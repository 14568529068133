import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Grid, Box } from '@mui/material';

import CustomSubmitButton from '../../button/SubmitButton';
import { RenterModalContext } from '../RenterModalContext';
import { addonsValidator } from '../validations/addRenterValidation';
import { CustomCheckbox, CustomDatePicker } from '../../customFormControl';
import { DateFormat, Severity } from '../../../../enum/enumList';
import NoRecordFound from '../../noRecordFound/NoRecordFound';
import { ADD_RENTER, EDIT_RENTER } from '../../../../graphql/api/reservation';
import { useAuth } from '../../../../hooks/useAuth';

interface IForm {
  addons1: boolean;
  addon1StartList: string;
  addons2: boolean;
  addon2StartList: string;
  addons3: boolean;
  addon3StartList: string;
  addons4: boolean;
  addon4StartList: string;
}

interface IAddonsDetail {
  closeModal: () => void;
}

export const AddonsDetail = ({ closeModal }: IAddonsDetail) => {
  const {
    handleBack,
    renterDetails,
    reservationDetails,
    snackbarShowMessage,
    updateRenterData,
    isFromDetails,
  } = useContext(RenterModalContext);

  const { user } = useAuth();
  const storeId = user?.selectedStore;
  const reservationId = JSON.parse(sessionStorage.getItem('reservationId') as string);

  const addonsList = renterDetails?.selectedPackage;
  const [addRenter, { loading: addRenterLoading }] = useMutation(ADD_RENTER);
  const [updateRenter, { loading: updateRenterLoading }] = useMutation(EDIT_RENTER);

  const formInitalValue: IForm = {
    addons1:
      renterDetails?.selectedPackage?.addons &&
      renterDetails?.selectedPackage?.addons.filter(
        (item) => item?.addonsId === renterDetails?.selectedPackage?.addon1Id,
      )?.length
        ? true
        : false,
    addon1StartList:
      renterDetails?.selectedPackage?.addons &&
      renterDetails?.selectedPackage?.addons.filter(
        (item) => item?.addonsId === renterDetails?.selectedPackage?.addon1Id,
      )?.length
        ? renterDetails?.selectedPackage?.addons.filter(
            (item) => item?.addonsId === renterDetails?.selectedPackage?.addon1Id,
          )[0]?.startDate
        : null,
    addons2:
      renterDetails?.selectedPackage?.addons &&
      renterDetails?.selectedPackage?.addons.filter(
        (item) => item?.addonsId === renterDetails?.selectedPackage?.addon2Id,
      )?.length
        ? true
        : false,
    addon2StartList:
      renterDetails?.selectedPackage?.addons &&
      renterDetails?.selectedPackage?.addons.filter(
        (item) => item?.addonsId === renterDetails?.selectedPackage?.addon2Id,
      )?.length
        ? renterDetails?.selectedPackage?.addons.filter(
            (item) => item?.addonsId === renterDetails?.selectedPackage?.addon2Id,
          )[0]?.startDate
        : null,
    addons3:
      renterDetails?.selectedPackage?.addons &&
      renterDetails?.selectedPackage?.addons.filter(
        (item) => item?.addonsId === renterDetails?.selectedPackage?.addon3Id,
      )?.length
        ? true
        : false,
    addon3StartList:
      renterDetails?.selectedPackage?.addons &&
      renterDetails?.selectedPackage?.addons.filter(
        (item) => item?.addonsId === renterDetails?.selectedPackage?.addon3Id,
      )?.length
        ? renterDetails?.selectedPackage?.addons.filter(
            (item) => item?.addonsId === renterDetails?.selectedPackage?.addon3Id,
          )[0]?.startDate
        : null,
    addons4:
      renterDetails?.selectedPackage?.addons &&
      renterDetails?.selectedPackage?.addons.filter(
        (item) => item?.addonsId === renterDetails?.selectedPackage?.addon4Id,
      )?.length
        ? true
        : false,
    addon4StartList:
      renterDetails?.selectedPackage?.addons &&
      renterDetails?.selectedPackage?.addons.filter(
        (item) => item?.addonsId === renterDetails?.selectedPackage?.addon4Id,
      )?.length
        ? renterDetails?.selectedPackage?.addons.filter(
            (item) => item?.addonsId === renterDetails?.selectedPackage?.addon4Id,
          )[0]?.startDate
        : null,
  };

  const { control, handleSubmit, watch, clearErrors, setValue, formState } = useForm({
    resolver: yupResolver(addonsValidator),
    defaultValues: formInitalValue,
  });
  const watchAddons1 = watch('addons1');
  const watchAddons1Value = watch('addon1StartList');
  const watchAddons2 = watch('addons2');
  const watchAddons2Value = watch('addon2StartList');
  const watchAddons3 = watch('addons3');
  const watchAddons3Value = watch('addon3StartList');
  const watchAddons4 = watch('addons4');
  const watchAddons4Value = watch('addon4StartList');

  useEffect(() => {
    clearErrors();
    if (!watchAddons1) setValue('addon1StartList', '');
    if (!watchAddons2) setValue('addon2StartList', '');
    if (!watchAddons3) setValue('addon3StartList', '');
    if (!watchAddons4) setValue('addon4StartList', '');

    if (watchAddons1 && !watchAddons1Value)
      setValue(
        'addon1StartList',
        reservationDetails?.addReservation?.reservationData?.startDate || new Date(),
      );
    if (watchAddons2 && !watchAddons2Value)
      setValue(
        'addon2StartList',
        reservationDetails?.addReservation?.reservationData?.startDate || new Date(),
      );
    if (watchAddons3 && !watchAddons3Value)
      setValue(
        'addon3StartList',
        reservationDetails?.addReservation?.reservationData?.startDate || new Date(),
      );
    if (watchAddons4 && !watchAddons4Value)
      setValue(
        'addon4StartList',
        reservationDetails?.addReservation?.reservationData?.startDate || new Date(),
      );
  }, [watchAddons1, watchAddons2, watchAddons3, watchAddons4]);

  const handleFormSubmit = (value: any) => {
    const addons = [];
    if (value.addons1)
      addons.push({
        addonId: addonsList?.addon1Id,
        startDate: reservationDetails?.addReservation?.reservationData?.startDate || new Date(),
      });
    if (value.addons2)
      addons.push({
        addonId: addonsList?.addon2Id,
        startDate: reservationDetails?.addReservation?.reservationData?.startDate || new Date(),
      });
    if (value.addons3)
      addons.push({
        addonId: addonsList?.addon3Id,
        startDate: reservationDetails?.addReservation?.reservationData?.startDate || new Date(),
      });
    if (value.addons4)
      addons.push({
        addonId: addonsList?.addon4Id,
        startDate: reservationDetails?.addReservation?.reservationData?.startDate || new Date(),
      });
    if (renterDetails?.renterId)
      updateRenter({
        variables: {
          editRenterArgs: {
            storeId,
            reservationId,
            addons,
            id: renterDetails?.renterId,
            firstName: renterDetails?.firstName,
            lastName: renterDetails?.lastName,
            gender: renterDetails?.gender,
            feet: renterDetails?.feet?.toString() || '0',
            inches: renterDetails?.inches?.toString() || '0',
            weight: (renterDetails?.weight && parseInt(renterDetails?.weight)) || 0,
            shoeSize: renterDetails?.shoeSize || '0',
            skierTypeId: renterDetails?.selectCategory?.skierTypeId || null,
            isSkiLength: renterDetails?.selectCategory?.isSkiLength || false,
            skiLengthPreference: renterDetails?.selectCategory?.skiLengthPreference,
            packageId: renterDetails?.selectedPackage?.id,
            DOB: new Date(renterDetails?.dob as any).toISOString().split('T')[0],
            categoryId: renterDetails?.selectCategory?.packageId,
          },
        },
        onCompleted(response) {
          const data = { ...response?.updateRenter?.data };
          updateRenterData(data, renterDetails?.renterId);
          closeModal();
        },
        onError(error) {
          if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
        },
      });
    else
      addRenter({
        variables: {
          addRenterArgs: {
            storeId,
            reservationId,
            addons,
            firstName: renterDetails?.firstName,
            lastName: renterDetails?.lastName,
            gender: renterDetails?.gender,
            feet: renterDetails?.feet?.toString() || '0',
            inches: renterDetails?.inches?.toString() || '0',
            weight: (renterDetails?.weight && parseInt(renterDetails?.weight)) || 0,
            shoeSize: renterDetails?.shoeSize || '0',
            skierTypeId: renterDetails?.selectCategory?.skierTypeId || null,
            isSkiLength: renterDetails?.selectCategory?.isSkiLength || false,
            skiLengthPreference: renterDetails?.selectCategory?.skiLengthPreference,
            packageId: renterDetails?.selectedPackage?.id,
            DOB: new Date(renterDetails?.dob as any).toISOString().split('T')[0],
            categoryId: renterDetails?.selectCategory?.packageId,
            isFromDetail: isFromDetails,
          },
        },
        onCompleted(response) {
          const data = [{ ...response?.addRenter?.data }];
          updateRenterData(data);
          closeModal();
        },
        onError(error) {
          if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
        },
      });
  };

  return (
    <>
      <DialogContent>
        <Grid
          container
          component='main'
          className='modal-lcol modal-lcol--border-none modal-lcol--mt'
          spacing={4}
        >
          <Box component='h3' className='block-heading'>
            Addons
          </Box>
          {addonsList?.addon1Id ||
          addonsList?.addon2Id ||
          addonsList?.addon3Id ||
          addonsList?.addon4Id ? (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box component='div' className='panel'>
                  <Box component='div' className='panel__header'>
                    <Grid item xs={12} md={7}>
                      <strong className='text-uppercase'>Addons</strong>
                    </Grid>
                  </Box>
                  {addonsList?.addon1Id && (
                    <Box component='div' className='panel__content-row'>
                      <Grid item xs={12} md={12}>
                        <Controller
                          control={control}
                          name='addons1'
                          render={({ field, formState }) => (
                            <CustomCheckbox
                              label={`${addonsList?.addon1Name} ($${
                                addonsList?.addon1Price ?? ''
                              })`}
                              field={field}
                              mainClassName=''
                              // error={formState.errors.addons1}
                            />
                          )}
                        />
                      </Grid>
                    </Box>
                  )}
                  {addonsList?.addon2Id && (
                    <Box component='div' className='panel__content-row'>
                      <Grid item xs={12} md={12}>
                        <Controller
                          control={control}
                          name='addons2'
                          render={({ field, formState }) => (
                            <CustomCheckbox
                              label={`${addonsList?.addon2Name} ($${
                                addonsList?.addon2Price ?? ''
                              })`}
                              field={field}
                              mainClassName=''
                              // error={formState.errors.addons2}
                            />
                          )}
                        />
                      </Grid>
                    </Box>
                  )}
                  {addonsList?.addon3Id && (
                    <Box component='div' className='panel__content-row'>
                      <Grid item xs={12} md={12}>
                        <Controller
                          control={control}
                          name='addons3'
                          render={({ field, formState }) => (
                            <CustomCheckbox
                              label={`${addonsList?.addon3Name} ($${
                                addonsList?.addon3Price ?? ''
                              })`}
                              field={field}
                              mainClassName=''
                            />
                          )}
                        />
                      </Grid>
                    </Box>
                  )}
                  {addonsList?.addon4Id && (
                    <Box component='div' className='panel__content-row'>
                      <Grid item xs={12} md={12}>
                        <Controller
                          control={control}
                          name='addons4'
                          render={({ field, formState }) => (
                            <CustomCheckbox
                              label={`${addonsList?.addon4Name} ($${
                                addonsList?.addon4Price ?? ''
                              })`}
                              field={field}
                              mainClassName=''
                            />
                          )}
                        />
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          ) : (
            <NoRecordFound text='No addons found' />
          )}
        </Grid>
      </DialogContent>
      <DialogActions className='modal__footer'>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button onClick={handleBack} sx={{ mr: 2 }} className='back-btn'>
            Previous
          </Button>

          <CustomSubmitButton
            onClick={handleSubmit(handleFormSubmit)}
            isLoading={addRenterLoading || updateRenterLoading}
          >
            Finish
          </CustomSubmitButton>
        </Box>
      </DialogActions>
    </>
  );
};
