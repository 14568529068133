import { createContext, useEffect, useMemo, useState } from 'react';

import { ICategories } from '../../../enum/enumList';
import { CustomSnackbar } from '../../../hoc/CustomSnackbar';
import { ISnackBar } from '../../../models/common';
import { IRenterDetails, ISkierTypeOptions } from '../../../models/reservation';
import { IPrefillData } from './AddEditRenterWrapper';

interface IRenterModalContext {
    renterDetails: IRenterDetails;
    reservationDetails: any;
    categories: ICategories[];
    activeStep: number;
    handleBack: () => void;
    handleNext: () => void;
    modelOpen: boolean;
    resetModal: () => void;
    setModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setRenterDeatils: React.Dispatch<React.SetStateAction<IRenterDetails>>;
    snackbarShowMessage?: ISnackBar;
    updateRenterData: (data: any, id?: string) => void;
    skierTypeOptions: ISkierTypeOptions[] | [];
    preFillData?: IPrefillData | null | undefined;
    isFromDetails?: boolean;
}

interface IRenterModalProvider {
    children: React.ReactNode;
    categories: ICategories[];
    modelOpen: boolean;
    setModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    details: any;
    editRenterDetails?: IRenterDetails;
    snackbarShowMessage?: ISnackBar;
    updateRenterData: (data: any, id?: string) => void;
    skierTypeOptions: ISkierTypeOptions[] | [];
    preFillData?: IPrefillData | null | undefined;
    isFromDetails?: boolean;
}

const steps = ['Renter Detail', 'Package', 'Sub Package', 'Addon'];

//  context for whole Renter Modal
export const RenterModalContext = createContext({} as IRenterModalContext);

const RenterModalProvider = ({
    children,
    categories,
    modelOpen,
    setModelOpen,
    details: reservationDetails,
    editRenterDetails,
    snackbarShowMessage,
    updateRenterData,
    skierTypeOptions,
    preFillData,
    isFromDetails = false,
}: IRenterModalProvider) => {
    const [renterDetails, setRenterDeatils] = useState(editRenterDetails || ({} as IRenterDetails));

    useEffect(() => {
        setRenterDeatils(editRenterDetails || ({} as IRenterDetails));
    }, [editRenterDetails]);

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());

    const resetModal = () => {
        setRenterDeatils({} as IRenterDetails);
        setActiveStep(0);
        setSkipped(new Set<number>());
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (activeStep !== 4) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (activeStep === steps.length - 1) {
            setActiveStep(3);
        }
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const value = useMemo(
        () => ({
            renterDetails,
            reservationDetails,
            categories,
            activeStep,
            handleBack,
            handleNext,
            modelOpen,
            setModelOpen,
            setRenterDeatils,
            resetModal,
            snackbarShowMessage,
            updateRenterData,
            skierTypeOptions,
            preFillData,
            isFromDetails,
        }),
        [
            renterDetails,
            activeStep,
            skipped,
            categories,
            modelOpen,
            setModelOpen,
            reservationDetails,
            updateRenterData,
            skierTypeOptions,
            editRenterDetails,
            preFillData,
            isFromDetails,
        ],
    );

    return <RenterModalContext.Provider value={value}>{children}</RenterModalContext.Provider>;
};

export default CustomSnackbar(RenterModalProvider);
