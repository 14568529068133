import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';

import { ASSIGN_ADDONS } from '../../../graphql/api/techStation';
import AddonsSingleCard from './AddonsSingleCard';
import { ModalTitle } from '../../sidenav/modal/ModalHeader';
import { IRenterDetails } from '../../../models/techStation';
import NoRecordFound from '../../common/noRecordFound/NoRecordFound';
import { TechContext } from '../../../pages/tech-station/TechStationWrapper';
import { Severity } from '../../../enum/enumList';
import { CustomSubmitButton } from '../..';
import moment from 'moment';

interface IAllRenterAddonsModal {
  isModalOpen: boolean;
  handleModalClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AllRenterAddonsModal = ({ isModalOpen, handleModalClose }: IAllRenterAddonsModal) => {
  const {
    addonsList,
    reservationEndDate,
    setAddonsList,
    reservationId,
    reservationStartDate,
    snackbarShowMessage,
    reservationType,
  } = useContext(TechContext);

  const [assginAddons, { loading: assginAddonsLoading }] = useMutation(ASSIGN_ADDONS);

  const handleSave = () => {
    assginAddons({
      variables: {
        updateRentersAddonsArgs: {
          reservationId: parseInt(reservationId || '0'),
          renters: addonsList.map((item) => {
            return {
              renterId: item.renterId,
              addons: item.addons
                .filter((addon) => addon?.status === 'assigned')
                .map((addon) => ({
                  addonId: addon.id,
                  startDate: moment(addon.startDate).format('YYYY-MM-DD'),
                  isSelected: !!addon?.isSelected,
                })),
            };
          }),
        },
      },
      onCompleted(data) {
        if (snackbarShowMessage)
          snackbarShowMessage('Addons has been assgined successfully', Severity.Success);
        handleModalClose();
        setAddonsList(
          data?.updateAddons?.data?.map((renter: IRenterDetails) => {
            return {
              renterId: renter?.id,
              firstName: renter?.firstName,
              lastName: renter?.lastName,
              addons: renter?.selectedPackage?.packageAddon ?? [],
            };
          }),
        );
      },
      onError(error) {
        if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
      },
    });
  };

  return (
    <BootstrapDialog
      onClose={handleModalClose}
      aria-labelledby='addon-title'
      fullWidth
      maxWidth={addonsList?.length === 1 ? 'md' : 'lg'}
      open={isModalOpen}
      className='modal'
    >
      <ModalTitle id='addon-title' className='modal__heading' onClose={handleModalClose}>
        Addons
      </ModalTitle>
      <DialogContent dividers>
        <Grid container component='main' className='modal-lcol modal-lcol--border-none' spacing={4}>
          {addonsList?.length ? (
            addonsList.map((renter) => (
              <AddonsSingleCard
                key={renter?.renterId}
                renterId={renter?.renterId}
                firstName={renter?.firstName}
                lastName={renter?.lastName}
                reservationStartDate={reservationStartDate}
                reservationEndDate={reservationEndDate}
                addonsList={
                  renter?.addons?.map((addon) => {
                    if (addon?.id === renter?.addon1Id) {
                      return { ...addon, price: renter?.addon1Price };
                    }
                    if (addon?.id === renter?.addon2Id) {
                      return { ...addon, price: renter?.addon2Price };
                    }

                    return addon;
                  }) || []
                }
                setAddonsList={setAddonsList}
                isOnlyRenter={addonsList?.length !== 1}
                reservationType={reservationType}
                // setReservationData={setReservationData}
              />
            ))
          ) : (
            <NoRecordFound text='No renters found' />
          )}
        </Grid>
      </DialogContent>
      <DialogActions className='modal__footer'>
        <Box sx={{ position: 'relative' }}>
          <CustomSubmitButton onClick={handleSave} isLoading={assginAddonsLoading}>
            Save
          </CustomSubmitButton>
        </Box>
        <Button className='cancel-btn' onClick={handleModalClose}>
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default AllRenterAddonsModal;
