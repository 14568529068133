import { useNavigate } from 'react-router';
import moment from 'moment';
import { MenuItem, TableCell, TableRow } from '@mui/material';
import {
  EngineeringOutlined as EngineeringOutlinedIcon,
  ClearOutlined as ClearOutlinedIcon,
} from '@mui/icons-material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditIcon from '@mui/icons-material/Edit';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { Messages, reservationStatus, Severity } from '../../enum/enumList';
import formatPrice from '../../utils/formatPrice';

const ReservationRenterListItem = (props: any) => {
  const {
    reservationRow,
    openEditRenterModal,
    setDeleteModal,
    setDeleteRenterId,
    openViewRenterModal,
    reservationData,
    snackbarShowMessage,
  } = props;

  const navigate = useNavigate();

  const handleDelete = () => {
    setDeleteRenterId(reservationRow?.detail?.id);
    setDeleteModal(true);
  };

  const handleViewClick = () => {
    setDeleteRenterId(reservationRow?.detail?.id);
    openViewRenterModal();
  };

  const handleAssignEquipment = () => {
    if (
      moment(reservationData.startDate).isAfter(new Date()) &&
      (reservationData.isPickupDayBefore
        ? moment(reservationData.startDate).subtract(1, 'days').isAfter(new Date())
        : true)
    ) {
      if (snackbarShowMessage)
        return snackbarShowMessage(
          'Cannot assign equipment before reservation start date',
          Severity.Error,
        );
    }

    // check is waiver is signed or not
    if (!reservationData?.isWaiverSigned) {
      if (snackbarShowMessage)
        return snackbarShowMessage(Messages.assignEquipmentError, Severity.Error);
    }

    navigate('/reservation/assign-equipment', {
      state: {
        renterId: reservationRow?.id,
      },
    });
  };

  return (
    <TableRow hover tabIndex={-1} key={reservationRow.srNo}>
      <TableCell id={reservationRow?.id} scope='row'>
        {reservationRow.srNo}
      </TableCell>
      <TableCell>{reservationRow.name}</TableCell>
      <TableCell>{reservationRow.age}</TableCell>
      <TableCell>{reservationRow.gender}</TableCell>
      <TableCell>{reservationRow.totalDays}</TableCell>
      <TableCell>{reservationRow.packageName}</TableCell>
      <TableCell>{formatPrice(reservationRow?.detail?.pricing?.totalAmount ?? 0)}</TableCell>
      <TableCell align='right'>
        <CustomThreeDotsMenu labelId={reservationRow?.id}>
          {(reservationData?.status === reservationStatus.processing ||
            reservationData?.status === reservationStatus.pending) && (
            <MenuItem onClick={handleAssignEquipment}>
              <EngineeringOutlinedIcon />
              Tech Station
            </MenuItem>
          )}
          {(reservationData?.status === reservationStatus.processing ||
            reservationData?.status === reservationStatus.pending) && (
            <MenuItem onClick={() => openEditRenterModal(reservationRow?.detail)}>
              <EditIcon />
              Edit
            </MenuItem>
          )}
          <MenuItem onClick={handleViewClick}>
            <RemoveRedEyeOutlinedIcon />
            View
          </MenuItem>
          {(reservationData?.status === reservationStatus.processing ||
            reservationData?.status === reservationStatus.pending) && (
            <MenuItem onClick={handleDelete}>
              <ClearOutlinedIcon />
              Delete
            </MenuItem>
          )}
        </CustomThreeDotsMenu>
      </TableCell>
    </TableRow>
  );
};

export default ReservationRenterListItem;
