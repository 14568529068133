import moment from 'moment';

interface IFormatDate {
  date: string | number;
  requiredHour?: boolean;
}
export const formatDate = ({ date, requiredHour = false }: IFormatDate) => {
  const momenDate = moment(date).isValid() ? moment.utc(date) : null;
  if (!momenDate) return 'N/A';
  return momenDate.format(`MM/DD/YYYY ${requiredHour ? 'HH:mm' : ''}`);
};
