import { createContext, lazy, useContext, useEffect, useMemo, useState } from 'react';
import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';

import { Loader } from '../../../../components';
import PricingSection from './pricingSection/PricingSection';
import PaymentDetails from './paymentDetails/PaymentDetails';
import { GET_PAYMENT_DETAILS } from '../../../../graphql/api/reservation';
import { ReservationContext } from '../ReservationContext';
import { useAuth } from '../../../../hooks/useAuth';
import {
  IGetPaymentData,
  CustomerProfile,
  IPaymentPricing,
  IPaymentTransaction,
  ICard,
} from '../../../../models/reservation';
import { ISnackBar } from '../../../../models/common';

// lazy import AddCardModal compoenent
const AddCardModal = lazy(() => import('../../AddCardModal'));

interface IPaymentTabContext {
  pricing: IPaymentPricing;
  card: CustomerProfile;
  transactions: IPaymentTransaction[];
  reservationId: number;
  updatePaymentTabData: (data: Partial<IGetPaymentData>) => void;
  snackbarShowMessage?: ISnackBar;
  openAddCardModal: () => void;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
}

export const PaymentTabContext = createContext({} as IPaymentTabContext);

const PaymentTab = () => {
  const [addCardModal, setAddCardModal] = useState(false);
  const { user } = useAuth();
  const { reservationData, snackbarShowMessage, refetch } = useContext(ReservationContext);
  const [paymentTabData, setPaymentTabData] = useState({} as IGetPaymentData);

  const { data, loading: getPaymentDetailsLoading } = useQuery(GET_PAYMENT_DETAILS, {
    variables: {
      reservationId: reservationData?.reservation?.id,
      storeId: user?.selectedStore,
      isAdmin: true,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setPaymentTabData(data?.getReservationPrice?.data || {});
  }, [data]);

  const updatePaymentTabData = (data: Partial<IGetPaymentData>) => {
    setPaymentTabData((prev) => ({ ...prev, ...data }));
  };
  const openAddCardModal = () => {
    setAddCardModal(true);
  };
  const closeAddCardModal = () => setAddCardModal(false);

  const onAddCardCompletion = (data?: ICard) => {
    if (data) updatePaymentTabData({ card: data });
    closeAddCardModal();
  };

  const contextValue = useMemo(
    () => ({
      pricing: paymentTabData?.pricing || {},
      card: paymentTabData?.card?.profile || {},
      transactions: paymentTabData?.transactions || [],
      reservationId: reservationData?.reservation?.id as number,
      openAddCardModal,
      updatePaymentTabData,
      snackbarShowMessage,
      refetch,
    }),
    [paymentTabData, reservationData, updatePaymentTabData, snackbarShowMessage, openAddCardModal],
  );

  if (getPaymentDetailsLoading)
    return (
      <Loader
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
          width: '100%',
        }}
      />
    );
  return (
    <PaymentTabContext.Provider value={contextValue}>
      <PricingSection />

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box
          component='div'
          className='inner-content__heading-outer inner-content__heading-outer--border-btm'
        >
          <Box component='h3'>Manage Payments</Box>
        </Box>
      </Grid>

      <PaymentDetails />

      <AddCardModal
        storeId={user?.selectedStore}
        modelOpen={addCardModal}
        setModelOpen={setAddCardModal}
        onAddCardCompletion={onAddCardCompletion}
        authNetClientKey={
          reservationData?.settings?.length
            ? reservationData?.settings[0]?.authorizeNetClientKey
            : ''
        }
        authNetLoginId={
          reservationData?.settings?.length ? reservationData?.settings[0]?.authorizeNetLoginId : ''
        }
      />
    </PaymentTabContext.Provider>
  );
};

export default PaymentTab;
