import { useContext, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { ApplyDiscountModal } from '../../../../../components';
import { PaymentTabContext } from '../PaymentTab';
import formatPrice from '../../../../../utils/formatPrice';

interface IRenderPricingRow {
  label: string;
  value: number;
  isNegative?: boolean;
}

const PricingSection = () => {
  const { pricing } = useContext(PaymentTabContext);

  const [openApplyDiscountModal, setOpenApplyDiscountModal] = useState(false);

  const RenderPricingRow = ({
    label,
    value,

    isNegative = false,
  }: IRenderPricingRow) => (
    <Box component='div' className='panel__content-row'>
      <Box component='label'>{label}</Box>
      <Box component='span' className='content-row__col'>
        {isNegative && value !== 0 ? '-' : ''} {formatPrice(value || 0)}
      </Box>
    </Box>
  );

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={10}>
        <Box component='div' className='panel panel--column panel--large-label'>
          <Box component='div' className='panel__header panel__header--twocol'>
            <strong className='text-uppercase'>Pricing</strong>
            <Box
              component='a'
              onClick={() => setOpenApplyDiscountModal(true)}
              sx={{ marginLeft: 2 }}
            >
              Apply Discount
            </Box>
          </Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              <RenderPricingRow label='Packages' value={pricing?.packages} />
              <RenderPricingRow label='Addons' value={pricing?.addons} />
              <RenderPricingRow
                label='Advance Res. Discount'
                value={pricing?.discount}
                isNegative
              />
              <RenderPricingRow
                label='Affiliate Discount'
                value={pricing?.affiliateDiscount}
                isNegative
              />
              <RenderPricingRow label='Admin Discount' value={pricing?.adminDiscount} isNegative />
              <RenderPricingRow label='Sub Total' value={pricing?.subTotal} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RenderPricingRow label='Tax' value={pricing?.tax} />
              <RenderPricingRow label='Total Amount' value={pricing?.totalAmount} />
              <RenderPricingRow label='Amount Paid' value={pricing?.amountPaid} />
              <RenderPricingRow label='Refund Amount' value={pricing?.refundAmount} />
              <RenderPricingRow label='Amount Due' value={pricing?.amountDue} />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <ApplyDiscountModal
        modalDiscountOpen={openApplyDiscountModal}
        setModalDiscountOpen={setOpenApplyDiscountModal}
      />
    </>
  );
};

export default PricingSection;
