import { useContext, useState } from 'react';
import { Button, DialogActions, DialogContent, Grid, Box, Radio, RadioGroup } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import * as Images from '../../../Images';
import CustomSubmitButton from '../../button/SubmitButton';
import { RenterModalContext } from '../RenterModalContext';
import NoRecordFound from '../../noRecordFound/NoRecordFound';
import { ISelectedPackages } from '../../../../models/reservation';
import { Severity } from '../../../../enum/enumList';
import { CustomTooltip } from '../../../CustomTooltip';

export const SubPackageDetail = () => {
  const {
    handleBack,
    renterDetails,
    categories,
    setRenterDeatils,
    handleNext,
    reservationDetails,
    snackbarShowMessage,
  } = useContext(RenterModalContext);

  const [selectedSubPackages, setSelectedSubPackages] = useState(
    renterDetails?.selectedPackage ? [renterDetails?.selectedPackage] : [],
  );
  const packages = renterDetails?.packages ?? [];
  const selectedCategory = categories?.find(
    (item) => item?.id === renterDetails?.selectCategory?.packageId,
  )?.name;

  const SinglePackageBox = ({ details }: { details: ISelectedPackages }) => (
    <Grid item xs={12} sm={4} md={4} lg={4}>
      <Box
        component='label'
        style={{ padding: '10px' }}
        className={`border-block border-block--fixed-width ${
          selectedSubPackages[0]?.id === details?.id ? 'border-block--selected' : ''
        }`}
        htmlFor={details?.id}
      >
        <Radio
          name='radio-for-sub-packages'
          id={details?.id}
          value={details?.id}
          checked={selectedSubPackages.length ? selectedSubPackages[0]?.id === details?.id : false}
        />
        <Box className='border-block__img border-block__img--sub-package'>
          <Box
            component='img'
            style={{ paddingTop: '10px' }}
            src={details?.image ?? Images.renterSportPackage}
            width={138}
            height={138}
            minWidth={138}
          />
          <Box className='border-block__price'>
            {details?.price
              ? `$${details?.price?.toFixed(2)}/${
                  reservationDetails.addReservation.reservationData.reservationType === 'D'
                    ? 'Day'
                    : 'Season'
                }`
              : 'N/A'}
          </Box>
        </Box>

        <Box className='border-block__desc'>
          {details?.description && (
            <CustomTooltip
              title={details?.description}
              arrow
              placement='top'
              className='tooltip__list'
            >
              <InfoIcon fontSize='small' />
            </CustomTooltip>
          )}
          {details?.name}
        </Box>
      </Box>
    </Grid>
  );

  const handleFormSubmit = () => {
    if (!selectedSubPackages.length) {
      if (snackbarShowMessage) snackbarShowMessage('Please select a package', Severity.Error);
    } else {
      setRenterDeatils((prev) => ({ ...prev, selectedPackage: selectedSubPackages[0] }));
      handleNext();
    }
  };

  return (
    <>
      <DialogContent>
        <Grid
          container
          component='main'
          className='modal-lcol modal-lcol--border-none modal-lcol--mt'
          spacing={4}
        >
          <Box component='h3' className='block-heading'>
            {selectedCategory}
          </Box>
          {packages.length ? (
            <>
              <RadioGroup
                row
                aria-labelledby='radio-for-sub-packages-label'
                name='radio-for-sub-packages'
                className='radio-block radio-block--inner-space'
                onChange={(e) => {
                  setSelectedSubPackages(() =>
                    packages.filter((item: ISelectedPackages) => item?.id === e.target.value),
                  );
                }}
              >
                <Grid container component='div' spacing={3}>
                  {packages.map((item: ISelectedPackages) => (
                    <SinglePackageBox key={item.id} details={item} />
                  ))}
                </Grid>
              </RadioGroup>
            </>
          ) : (
            <NoRecordFound text='No packages found' />
          )}
        </Grid>
      </DialogContent>

      <DialogActions className='modal__footer'>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button onClick={handleBack} sx={{ mr: 2 }} className='back-btn'>
            Previous
          </Button>

          <CustomSubmitButton onClick={() => handleFormSubmit()} disabled={!packages.length}>
            Next
          </CustomSubmitButton>
        </Box>
      </DialogActions>
    </>
  );
};
