import { Box, MenuItem, TableCell, TableRow } from '@mui/material';
import moment from 'moment-timezone';
import {
  Edit as EditIcon,
  CancelOutlined as CancelOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlineOutlinedIcon,
  FileCopyOutlined as FileCopyIcon,
} from '@mui/icons-material';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { useMemo } from 'react';

type stores = { name: string; id: number };

const AffiliateListItem = ({
  reservationRow,
  editAffiliate,
  changeStatus,
  stores,
  copyAffiliateCode,
}: any) => {
  const { status, __typename, createdAt, stores: selectedStores, ...restData } = reservationRow;
  const userTimeZone = moment.tz.guess() || 'UTC';
  const sortedStores = useMemo(
    () =>
      reservationRow.stores.sort((a: stores, b: stores) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }),
    [reservationRow.stores],
  );

  return (
    <TableRow hover tabIndex={-1} key={reservationRow.srNo}>
      <TableCell>{reservationRow.affiliateName}</TableCell>
      <TableCell id={reservationRow.srNo} scope='reservationRow'>
        <span className='icon-text'>
          <FileCopyIcon onClick={() => copyAffiliateCode(reservationRow?.code)} />
          {reservationRow?.code ?? ''}
        </span>
      </TableCell>
      <TableCell>{reservationRow.discount}%</TableCell>
      <TableCell>{`${
        reservationRow.validFrom
          ? moment(reservationRow.validFrom).tz(userTimeZone).format('MM/DD/YYYY')
          : 'N/A'
      } - ${
        reservationRow.validTo
          ? moment(reservationRow.validTo).tz(userTimeZone).format('MM/DD/YYYY')
          : 'N/A'
      }`}</TableCell>
      <TableCell>
        <Box
          component='span'
          className={reservationRow.status ? 'badge badge--green' : 'badge badge--orange'}
        >
          {reservationRow.status ? 'Active' : 'Inactive'}
        </Box>
      </TableCell>
      <TableCell>{sortedStores.map((item: stores) => item.name).join(', ')}</TableCell>
      <TableCell>
        {reservationRow.createdAt &&
          moment(reservationRow.createdAt).tz(userTimeZone).format('MM/DD/YYYY')}
      </TableCell>
      <TableCell align='right'>
        <CustomThreeDotsMenu labelId={reservationRow?.srNo}>
          <MenuItem
            onClick={() =>
              editAffiliate({
                ...restData,
                validFrom:
                  reservationRow.validFrom &&
                  moment(reservationRow.validFrom).tz(userTimeZone).format('MM/DD/YYYY'),
                validTo:
                  reservationRow.validTo &&
                  moment(reservationRow.validTo).tz(userTimeZone).format('MM/DD/YYYY'),
                stores: stores.map((item: any) => {
                  const result = selectedStores.find((data: any) => data.id == item.id);
                  const { name, id } = item;
                  return { name, storeId: id, value: result?.id ? true : false };
                }),
              })
            }
          >
            <EditIcon />
            Edit Affiliate
          </MenuItem>
          <MenuItem onClick={() => changeStatus(reservationRow.id, reservationRow.status)}>
            {reservationRow.status ? (
              <>
                <CancelOutlinedIcon />
                Inactivate Affiliate
              </>
            ) : (
              <>
                <CheckCircleOutlineOutlinedIcon /> Activate Affiliate
              </>
            )}
          </MenuItem>
        </CustomThreeDotsMenu>
      </TableCell>
    </TableRow>
  );
};

export default AffiliateListItem;
