import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Grid,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress,
  Button,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import { barcodeList } from './barCodeDetailsList';
import { IInvertoryType } from '../../models/inventory';
import useDebounce from '../../hooks/useDebounce';
import {
  ASSIGN_EQUIPMENT,
  GET_BARCODE_DETAILS,
  MANUAL_ASSIGN_EQUIPMENT,
} from '../../graphql/api/techStation';
import { CustomSelect, CustomSubmitButton } from '..';
import { inventoryTypeEnum, Severity } from '../../enum/enumList';
import { useAuth } from '../../hooks/useAuth';
import { TechContext } from '../../pages/tech-station/TechStationWrapper';
import { IRenterDetails } from '../../models/techStation';

const menuItems = [
  { value: 'Alpine', label: 'Alpine' },
  { value: 'Grip Walk', label: 'Grip Walk' },
  { value: 'Touring', label: 'Touring' },
];

interface IAssignEquipmentAccordian {
  renterData: IRenterDetails;
  reservationId: number;
  renterId: string;
  inventory: IInvertoryType;

  dinValue?: number | null;
  setDinValue: React.Dispatch<React.SetStateAction<number | null>>;
  getDinValue: (value: number) => void;
}

interface IManualEquipment {
  id: null;
  isSelected: boolean;
  dropDownValue: string;
  bindingModal?: string;
  length?: number | null;
}

const AssignEquipmentAccordian = ({
  renterData,
  inventory,
  reservationId,
  renterId,
  dinValue,
  setDinValue,
  getDinValue,
}: IAssignEquipmentAccordian) => {
  const { user } = useAuth();
  const { snackbarShowMessage, setRenterList } = useContext(TechContext);
  const storeId = user?.selectedStore;

  const [manualEquipment, setManualEquipment] = useState<IManualEquipment>({
    id: null,
    isSelected: false,
    dropDownValue: '',
    bindingModal: '',
    length: null,
  });
  const [expanded, setExpanded] = useState<string | boolean>(true);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [barcodeTextInput, setBarcodeTextInput] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [equimentData, setEquimentData] = useState<any>(null);

  const debouncedValue = useDebounce<string>(barcodeTextInput, 100);
  const manualLengthDebouncedValue = useDebounce(manualEquipment.length, 500);

  const [getBarcodeDetails, { loading: barcodeDetailsLoading, data, error }] = useLazyQuery(
    GET_BARCODE_DETAILS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [manualAssignEquipment, { loading: manualAssignEquipmentLoading }] =
    useMutation(MANUAL_ASSIGN_EQUIPMENT);

  const [assignEquipment, { loading: assignEquipmentLoading }] = useMutation(ASSIGN_EQUIPMENT, {
    onError(error) {
      if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
    },
  });

  const barcodeDetails = barcodeList(equimentData || null);
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // whenever debouncedValue changes, call the getBarcodeDetails api
  useEffect(() => {
    if (debouncedValue && !isDisabled)
      getBarcodeDetails({
        variables: {
          barcode: debouncedValue,
          inventoryType: inventory?.slug,
          reservationId,
          renterId,
          storeId,
        },
        onCompleted(data) {
          if (inventory?.slug === inventoryTypeEnum.skiBoots) {
            setDinValue(data?.getBarcodeDetails?.data?.din || null);
          }

          setRenterList((prev: IRenterDetails[]) =>
            prev.map((item) => {
              if (item.id === data?.getBarcodeDetails?.data?.id) {
                return data?.getBarcodeDetails?.data;
              }
              return item;
            }),
          );

          if (snackbarShowMessage)
            snackbarShowMessage('Equipment assigned successfully', Severity.Success);
          // handleSave();
        },
        onError() {
          if (inventory?.slug === inventoryTypeEnum.skiBoots) setDinValue(null);
        },
      });

    if (!debouncedValue) {
      setErrorMsg('');
      setEquimentData(null);
    }
  }, [debouncedValue]);

  // update error message if any
  useEffect(() => {
    if (error && error.message) {
      setErrorMsg(error.message);
    }
  }, [error]);

  // update equipment data
  useEffect(() => {
    if (data) {
      setEquimentData(data?.getBarcodeDetails?.equipmentData || null);
    }
  }, [data]);

  useEffect(() => {
    if (
      renterData?.equipments?.manual &&
      (inventory?.slug === inventoryTypeEnum.skis || inventory?.slug === inventoryTypeEnum.skiBoots)
    ) {
      renterData?.equipments?.manual?.forEach((item: any) => {
        if (
          item?.inventoryType === inventoryTypeEnum.skis &&
          inventory?.slug === inventoryTypeEnum.skis
        ) {
          setManualEquipment({
            id: item?.id,
            isSelected: true,
            dropDownValue: item?.type,
            bindingModal: item?.bindingModel,
            length: item?.length,
          });
          setIsDisabled(true);
        }
        if (
          item?.inventoryType === inventoryTypeEnum.skiBoots &&
          inventory?.slug === inventoryTypeEnum.skiBoots
        ) {
          setManualEquipment({
            id: item?.id,
            isSelected: true,
            dropDownValue: item?.type,
            bindingModal: item?.bindingModel,
            length: item?.length,
          });
          setIsDisabled(true);
        }
        if (item?.inventoryType === null) {
          setManualEquipment({
            id: null,
            isSelected: false,
            dropDownValue: '',
            bindingModal: '',
            length: null,
          });
          setIsDisabled(false);
        }
      });
    }
    if (renterData?.equipments?.equipment) {
      const equipment = renterData?.equipments?.equipment?.find(
        (item: any) => item?.inventories_inventoryTypeId === inventory?.id,
      );

      setBarcodeTextInput(equipment?.barcode1 || '');
      setEquimentData(equipment || null);

      if (equipment?.barcode1) setIsDisabled(true);
      else if (!equipment) {
        setIsDisabled(false);
      }
    }
  }, [renterData]);

  // check if length is updated then hit din setting api for ski boots
  useEffect(() => {
    if (inventory?.slug === inventoryTypeEnum.skiBoots) {
      if (manualLengthDebouncedValue) {
        getDinValue(manualLengthDebouncedValue);
      }
    }
  }, [manualLengthDebouncedValue]);

  const handleSave = () => {
    if (manualEquipment.isSelected) {
      manualAssignEquipment({
        variables: {
          assignManualEquipmentArgs: {
            inventoryType: inventory?.slug,
            bindingModel: manualEquipment?.bindingModal,
            type: manualEquipment?.dropDownValue,
            length: manualEquipment?.length,
            id: manualEquipment?.id,
            renterId,
            storeId,
            reservationId,
          },
        },
        onCompleted(data) {
          setRenterList((prev: IRenterDetails[]) =>
            prev.map((item) => {
              if (item.id === data?.assignManualEquipment?.data?.id) {
                return data?.assignManualEquipment?.data;
              }
              return item;
            }),
          );
          if (snackbarShowMessage)
            snackbarShowMessage(data.assignManualEquipment.message, Severity.Success);
        },
        onError(error) {
          if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
        },
      });
    } else {
      assignEquipment({
        variables: {
          storeId,
          reservationId,
          renterId,
          equipmentId: equimentData?.equipmentId,
          inventoryType: inventory?.slug,
          din: dinValue?.toString(),
        },
        onCompleted: (data) => {
          setManualEquipment((prev) => ({
            ...prev,
            dropDownValue: '',
            bindingModal: '',
            length: null,
          }));
          setRenterList((prev: IRenterDetails[]) =>
            prev.map((item) => {
              if (item.id === data?.assignEquipment?.data?.id) {
                return data?.assignEquipment?.data;
              }
              return item;
            }),
          );

          if (snackbarShowMessage)
            snackbarShowMessage('Equipment assigned successfully', Severity.Success);
        },
        onError: (error) => {
          if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
        },
      });
    }
  };

  const handleEditEquipment = () => {
    setIsDisabled(false);
    // setBarcodeTextInput('');
    // setEquimentData(null);
  };

  return (
    <Grid item sm={12}>
      <Accordion
        expanded={typeof expanded === 'string' ? expanded === inventory?.id : expanded}
        onChange={handleAccordionChange(inventory?.id)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Box component='div' className='accordion-headerrow'>
            <Typography>{inventory?.name || ''}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {(inventory?.slug === inventoryTypeEnum.skis ||
            inventory?.slug === inventoryTypeEnum.skiBoots) && (
            <FormControlLabel
              control={
                <Switch
                  checked={manualEquipment.isSelected}
                  onChange={() =>
                    setManualEquipment((prev) => ({
                      ...prev,
                      isSelected: !prev.isSelected,
                      dropDownValue: '',
                      bindingModal: '',
                      length: null,
                    }))
                  }
                  name='gilad'
                />
              }
              label='Assign Manually'
            />
          )}
          {manualEquipment.isSelected ? (
            inventory?.slug === inventoryTypeEnum.skis ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Box component='label' className='label'>
                      Binding Model
                    </Box>
                    <TextField
                      fullWidth
                      name='snowboard-length'
                      placeholder='Binding Model'
                      type='text'
                      value={manualEquipment?.bindingModal || ''}
                      onChange={(e) =>
                        setManualEquipment((prev) => ({
                          ...prev,
                          bindingModal: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box component='label' className='label'>
                      Binding Type
                    </Box>
                    <CustomSelect
                      id='feet'
                      label=''
                      mainClassName='label label--width '
                      placeholder='--Select Binding Type--'
                      menuItems={menuItems}
                      field={{
                        value: manualEquipment?.dropDownValue || '',
                        onChange: (e: any) =>
                          setManualEquipment((prev) => ({
                            ...prev,
                            dropDownValue: e.target.value,
                          })),
                      }}
                      fullWidth
                      showLabel={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box component='label' className='label'>
                      Din Value
                    </Box>
                    <TextField
                      fullWidth
                      name='snowboard-length'
                      placeholder='N/A'
                      type='text'
                      value={dinValue || ''}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : inventory?.slug === inventoryTypeEnum.skiBoots ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Box component='label' className='label'>
                      Sole Type
                    </Box>
                    <CustomSelect
                      id='feet'
                      label=''
                      mainClassName='label label--width '
                      placeholder='--Select Binding Type--'
                      menuItems={menuItems}
                      showLabel={false}
                      field={{
                        value: manualEquipment?.dropDownValue || '',
                        onChange: (e: any) =>
                          setManualEquipment((prev) => ({
                            ...prev,
                            dropDownValue: e.target.value,
                          })),
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box component='label' className='label'>
                      Length
                    </Box>
                    <TextField
                      name='snowboard-length'
                      placeholder='Length'
                      type='number'
                      value={manualEquipment?.length || ''}
                      onChange={(e) => {
                        setManualEquipment((prev) => ({
                          ...prev,
                          length: parseInt(e.target.value),
                        }));
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box component='label' className='label'>
                      Din Value
                    </Box>
                    <TextField
                      fullWidth
                      name='snowboard-length'
                      placeholder='N/A'
                      type='text'
                      value={dinValue || ''}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null
          ) : (
            <Box component='div' className='custom-panel'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Box component='label' className='label'>
                    Barcode<Box component='span'>*</Box>
                  </Box>
                  <TextField
                    fullWidth
                    name='Barcode'
                    placeholder='Barcode'
                    type='text'
                    value={barcodeTextInput}
                    disabled={isDisabled}
                    onChange={(e) => {
                      setErrorMsg('');
                      setBarcodeTextInput(e.target.value);
                      if (
                        (inventory?.slug === inventoryTypeEnum.skiBoots ||
                          inventory?.slug === inventoryTypeEnum.skis) &&
                        dinValue
                      ) {
                        setDinValue(null);
                      }
                    }}
                  />

                  <Grid item xs={12} sm={12} className='mt-2'>
                    <Box component='label' className='label'>
                      Din Value
                    </Box>
                    <TextField
                      fullWidth
                      name='snowboard-length'
                      placeholder='N/A'
                      type='text'
                      value={dinValue || ''}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8} className='custom-panel__rgt'>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Box component='label' className='label label--sub-heading'>
                        Details
                      </Box>
                    </Grid>

                    {barcodeDetailsLoading || errorMsg || barcodeDetails?.length ? (
                      barcodeDetailsLoading ? (
                        <Grid item xs={12} sm={12} className='pt-0'>
                          <CircularProgress style={{ height: 25, width: 25 }} />
                        </Grid>
                      ) : errorMsg ? (
                        <Grid item xs={12} sm={12} className='pt-0'>
                          <Typography color='red'>{errorMsg}</Typography>
                        </Grid>
                      ) : (
                        barcodeDetails.map((detail) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={3}
                            className='pt-0 mb-1'
                            key={detail?.field?.label}
                          >
                            <Box component='div' className='modal-row'>
                              <Box component='label' className='modal-row__heading'>
                                {detail?.field?.label || ''}
                              </Box>
                              <Box component='div' className='modal-row__desc'>
                                {detail?.value || 'N/A'}
                              </Box>
                            </Box>
                          </Grid>
                        ))
                      )
                    ) : (
                      <Grid item xs={12} sm={12} className='pt-0'>
                        Please scan barcode to get details
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box className='btn-wrap btn-wrap--align-right btn-wrap--mt-sm'>
            {isDisabled && !manualEquipment.isSelected ? (
              <Button type='button' className='primary-btn' onClick={handleEditEquipment}>
                Edit
              </Button>
            ) : (
              <CustomSubmitButton
                onClick={handleSave}
                disabled={
                  barcodeDetailsLoading || manualEquipment.isSelected
                    ? inventory?.slug === inventoryTypeEnum.skis
                      ? !manualEquipment.dropDownValue || !manualEquipment?.bindingModal
                      : !manualEquipment.dropDownValue || !manualEquipment?.length
                    : !debouncedValue || !!errorMsg
                }
                isLoading={assignEquipmentLoading || manualAssignEquipmentLoading}
              >
                {manualEquipment?.id ? 'Update' : 'Save'}
              </CustomSubmitButton>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default AssignEquipmentAccordian;
