import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { createContext, lazy, useEffect, useMemo, useState } from 'react';

import { reservationStatus, Severity } from '../../enum/enumList';
import { GET_RESERVATION_PROPERTIES } from '../../graphql/api/reservation';
import { useAuth } from '../../hooks/useAuth';
import { IPackageAddons, IRenterDetails } from '../../models/techStation';
import { CustomSnackbar } from '../../hoc/CustomSnackbar';
import { ISnackBar } from '../../models/common';

import '../reservation/Reservation.scss';

const TechStationListing = lazy(() => import('./TechStationListing'));
const AssignEquipment = lazy(() => import('./AssignEquipment'));

export interface IAddonsList {
  addons: IPackageAddons[];
  renterId: string;
  firstName: string;
  lastName: string;
  addon1Id: string;
  addon2Id: string;
  addon1Price: number;
  addon2Price: number;
}

interface ITechContext {
  apiRenterList: IRenterDetails[];
  renterList: IRenterDetails[];
  reservationId: string;
  reservationStartDate: string;
  reservationEndDate: string;
  addonsList: IAddonsList[];
  setAddonsList: (data: any) => void;
  setRenterList: (data: any) => void;
  snackbarShowMessage?: ISnackBar;
  refetchEquipments: (renterId: string) => void;
  mainDataLoading: boolean;
  reservationStoreId: number;
  reservationType: string;
}

interface ITechContextWrapper {
  snackbarShowMessage?: ISnackBar;
}

export const TechContext = createContext({} as ITechContext);

const TechContextWrapper = ({ snackbarShowMessage }: ITechContextWrapper) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, currentSeason } = useAuth();
  const storeId = user?.selectedStore;
  const reservationId = sessionStorage.getItem('reservationId') ?? '';

  const [renterList, setRenterList] = useState<IRenterDetails[]>([]);
  const [addonsList, setAddonsList] = useState<IAddonsList[]>([]);

  const [getReservationApiInfo, { data: reservationApiData, loading }] = useLazyQuery(
    GET_RESERVATION_PROPERTIES,
    {
      fetchPolicy: 'network-only',
    },
  );

  // if reservationID is not present in local storage then redirect to reservation page
  useEffect(() => {
    if (!reservationId) navigate('/reservations');

    if (reservationId) {
      getReservationApiInfo({
        variables: {
          reservationId: parseInt(reservationId) ?? null,
          storeId,
        },
        onCompleted(data) {
          if (
            data?.getReservationProperties?.data?.reservation?.status ===
              reservationStatus.pending ||
            data?.getReservationProperties?.data?.reservation?.status ===
              reservationStatus.cancelled
          ) {
            setTimeout(() => {
              sessionStorage.clear();
              return navigate('/reservations');
            }, 3000);

            if (snackbarShowMessage)
              return snackbarShowMessage(
                'Cannot assign equipment to cancelled or pending reservation',
                Severity.Error,
              );
          }

          // redirect to reservation if try to aceess tech station page before reservation is started
          if (
            moment(data?.getReservationProperties?.data?.reservation?.startDate).isAfter(
              new Date(),
            ) &&
            (data?.getReservationProperties?.data?.reservation?.isPickupDayBefore
              ? moment(data?.getReservationProperties?.data?.reservation?.startDate)
                  .subtract(1, 'days')
                  .isAfter(new Date())
              : true)
          ) {
            setTimeout(() => {
              sessionStorage.clear();
              return navigate('/reservations');
            }, 3000);

            if (snackbarShowMessage)
              return snackbarShowMessage(
                'Cannot assign equipment before reservation start date',
                Severity.Error,
              );
          }

          setRenterList(data?.getReservationProperties?.data?.renter);
          setAddonsList(
            data?.getReservationProperties?.data?.renter?.map((renter: IRenterDetails) => {
              return {
                renterId: renter?.id,
                firstName: renter?.firstName,
                lastName: renter?.lastName,
                addons: renter?.selectedPackage?.packageAddon ?? [],
                addon1Id: renter?.selectedPackage?.addon1Id,
                addon1Price: renter?.selectedPackage?.addon1Price,
                addon2Id: renter?.selectedPackage?.addon2Id,
                addon2Price: renter?.selectedPackage?.addon2Price,
              };
            }),
          );
        },
        onError() {
          sessionStorage.removeItem('reservationId');
          navigate('/reservations');
        },
      });
    }
  }, [reservationId, pathname]);

  const refetchEquipments = (renterId: string) => {
    const rentersListCopy = [...renterList];
    const addOnscopy = JSON.parse(JSON.stringify(addonsList));
    const modifiedRentersList = rentersListCopy?.map((renter) => {
      if (renter.id === renterId) {
        return {
          ...renter,
          equipmentAssigned: 'no',
          equipments: {
            ...renter.equipments,
            equipment: [],
            manual: [
              {
                id: null,
                isSelected: false,
                dropDownValue: '',
                bindingModal: '',
                length: null,
                inventoryType: null,
                name: null,
              },
            ],
          },
        };
      }
      return renter;
    });

    const addOnResult =
      addOnscopy?.map((item: any) => {
        if (item.renterId === renterId) {
          const { addons = [] } = item || {};
          const addonCyp = [...addons];
          addonCyp.map((addOnData) => {
            if (addOnData.status === 'assigned') {
              addOnData.status = 'pending';
            }
            return addOnData;
          });
        }
        return { ...item };
      }) || [];
    setRenterList(modifiedRentersList);
    setAddonsList(addOnResult);
  };

  const value = useMemo(
    () => ({
      apiRenterList: reservationApiData?.getReservationProperties?.data?.renter ?? [],
      renterList,
      reservationId,
      reservationStoreId: reservationApiData?.getReservationProperties?.data?.reservation?.storeId,
      reservationStartDate:
        reservationApiData?.getReservationProperties?.data?.reservation?.startDate ?? '',
      reservationEndDate:
        reservationApiData?.getReservationProperties?.data?.reservation?.endDate ?? '',
      reservationType:
        reservationApiData?.getReservationProperties?.data?.reservation?.reservationType ?? '',
      addonsList,
      setAddonsList,
      setRenterList,
      snackbarShowMessage,
      refetchEquipments,
      mainDataLoading: loading,
    }),
    [renterList, reservationApiData, addonsList, snackbarShowMessage, loading],
  );

  const RenderedContent = () => {
    switch (pathname) {
      case '/reservation/tech-listing':
        return <TechStationListing />;
      case '/reservation/assign-equipment':
        return <AssignEquipment />;

      default:
        return <TechStationListing />;
    }
  };
  return <TechContext.Provider value={value}>{RenderedContent()}</TechContext.Provider>;
};

export default CustomSnackbar(TechContextWrapper);
