import moment from 'moment';
import { Box, TableCell, TableRow } from '@mui/material';
import { TextSnippetOutlined as TextSnippetOutlinedIcon } from '@mui/icons-material';
import { equipmentReturn } from '../../enum/enumList';

interface IReservationReturnListItem {
  reservationRow: any;
  handleReturnsDetailsClick: () => void;
  startDate: string;
  endDate: string;
}

const ReservationReturnListItem = ({
  reservationRow,
  handleReturnsDetailsClick,
  startDate,
  endDate,
}: IReservationReturnListItem) => (
  <TableRow
    key={reservationRow?.id}
    className={
      !(reservationRow?.isReturned === equipmentReturn.yes) &&
      moment(reservationRow?.endDate).isBefore(new Date())
        ? 'item-retured-no'
        : ''
    }
  >
    <TableCell scope='row'>{reservationRow?.renterName || 'N/A'}</TableCell>
    <TableCell>{startDate}</TableCell>
    <TableCell>{endDate}</TableCell>
    <TableCell>
      <Box
        component='span'
        className={`badge text-capitalize ${
          reservationRow?.status === 'no'
            ? 'badge--red'
            : reservationRow?.status === 'partially'
            ? 'badge--blue'
            : 'badge--green'
        } text-capitalize`}
      >
        {reservationRow?.status}
      </Box>
    </TableCell>
    <TableCell>
      <Box component='span' className='link-btn' onClick={handleReturnsDetailsClick}>
        <TextSnippetOutlinedIcon /> details
      </Box>
    </TableCell>
  </TableRow>
);

export default ReservationReturnListItem;
